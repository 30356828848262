import React from "react"
import Layout2 from "../layouts/layout2"
import LoginCard from "../components/loginCard"

const Login = (props) => {
  return (
    <Layout2>
      <section className="ptb-100">
        <h2 className="title dark">Log In!</h2>
        <section className="login-card-wrapper">
          <LoginCard loginCard="school" loginLink={process.env.GATSBY_MGMT_LOGIN} description="This is login for the school"/>
          <LoginCard loginCard="courses" loginLink={process.env.GATSBY_B2C_LMS_URL} description="This is login for the courses"/>
          {/* <LoginCard loginCard="coding" loginLink={process.env.GATSBY_CODING_LOGIN} description="This is login for the coding"/> */}
        </section>
      </section>
    </Layout2>
  )
}
export default Login
