import React from 'react'
import { Link } from "gatsby"
import "./LoginCard.scss"

const LoginCard = (props) => {
    return (
        <Link to={props.loginLink} className='login-card-wrap' >
            <div className='login-card-icon'>
                <img src={`/${props.loginCard}.svg`}/>
            </div>
            <div className="login-card-p">
                <p className='login-card-text'>{props.loginCard} login</p>
                <p className='login-card-desc'>{props.description}</p>
            </div>
        </Link>
    )
}

export default LoginCard